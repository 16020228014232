import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    width: "100%",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ButtonWithLoading = ({ loading, disabled, ...rest }) => {
  const classes = useStyles();
  const isDisabled = disabled || loading;

  return (
    <div className={classes.wrapper}>
      <Button {...rest} disabled={isDisabled} />
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

ButtonWithLoading.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

ButtonWithLoading.defaultProps = {
  loading: false,
  disabled: false,
};

export default ButtonWithLoading;
