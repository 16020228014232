import { SnackbarProvider } from "notistack";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import { WalletProvider } from "context";
import { Header, Form } from "components";

function App() {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <WalletProvider>
        <Container maxWidth="md">
          <Box m={2}>
            <Paper elevation={3}>
              <Box p={1}>
                <Header />

                <Box mt={1}>
                  <Form />
                </Box>
              </Box>
            </Paper>
          </Box>
        </Container>
      </WalletProvider>
    </SnackbarProvider>
  );
}

export default App;
