import { useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { TextField, Toolbar, ButtonWithLoading } from "components";

const useStyles = makeStyles(() => ({
  fileGrid: {
    display: "flex",
    alignItems: "center",
  },
  fileInput: {
    display: "none",
  },
  fileLabel: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadButton: {
    width: 200,
  },
}));

const UploadFileForm = ({ onSubmit, hash: ipfsHash, loading, children }) => {
  const classes = useStyles();
  const { handleSubmit, register, watch, setValue } = useForm();
  const imageField = watch("image", []);
  const imageValue = imageField[0]?.name || "";

  useEffect(() => {
    if (!ipfsHash) {
      setValue("image", "");
    }
  }, [ipfsHash, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Toolbar>Step 1 - upload NFT image to IPFS</Toolbar>

      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item md={6} className={classes.fileGrid}>
            <label
              htmlFor="contained-button-file"
              className={classes.fileLabel}
            >
              <TextField
                label="NFT image"
                value={imageValue}
                placeholder="Upload image..."
                inputProps={{
                  readOnly: true,
                }}
              />
              <input
                id="contained-button-file"
                accept="image/*"
                type="file"
                {...register("image")}
                className={classes.fileInput}
              />
              <Button variant="contained" component="span">
                Upload
              </Button>
            </label>

            <Box ml={1} display="inline">
              <ButtonWithLoading
                color="primary"
                variant="contained"
                type="submit"
                disabled={!imageValue}
                loading={loading}
                className={classes.fileUploadButton}
              >
                Upload image to IPFS
              </ButtonWithLoading>
            </Box>
          </Grid>

          <Grid item md={6}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

UploadFileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hash: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default UploadFileForm;
