import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { NFT_PROPERTIES, NFT_LEVELS } from "config";
import { generateFieldName, hasErrorField } from "helpers/form";
import { TextField, Toolbar, ButtonWithLoading } from "components";

const MetadataForm = ({ isDisabled, onSubmit, hash, loading, children }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  useEffect(() => {
    if (!hash) {
      reset();
    }
  }, [hash, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Toolbar>Step 2 - upload NFT METADATA to IPFS</Toolbar>

      <Box marginY={1}>
        <Toolbar color="secondary" size="small">
          NFT general data
        </Toolbar>
      </Box>

      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box mb={3}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { value = "", onChange, ref } }) => (
                  <TextField
                    label="NFT token name"
                    fullWidth
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    error={Boolean(errors.name)}
                  />
                )}
              />
            </Box>

            <Box mb={3}>
              <Controller
                name="external_url"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^https:\/|^http:\//,
                    message: "External link should starts from http(s)://",
                  },
                }}
                render={({ field: { value = "", onChange, ref } }) => {
                  const isPatternError =
                    errors.external_url &&
                    errors.external_url.type === "pattern";

                  return (
                    <TextField
                      placeholder="http(s)://"
                      label="External URL"
                      fullWidth
                      autoComplete="off"
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      error={Boolean(errors.external_url)}
                      helperText={isPatternError && errors.external_url.message}
                    />
                  );
                }}
              />
            </Box>
          </Grid>

          <Grid item md={6}>
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={({ field: { value = "", onChange, ref } }) => (
                <TextField
                  label="NFT token description"
                  fullWidth
                  autoComplete="off"
                  multiline
                  rows={5}
                  value={value}
                  onChange={onChange}
                  inputRef={ref}
                  error={Boolean(errors.description)}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box marginY={1}>
        <Toolbar color="secondary" size="small">
          NFT properties
        </Toolbar>
      </Box>

      <Box p={3}>
        <Grid container spacing={2}>
          {NFT_PROPERTIES.map(({ name, options }, index) => {
            const fieldName = generateFieldName("accessories", index, name);
            const isError = hasErrorField(errors, "accessories", index, name);

            if (!name) {
              return <Grid item md={6} key={fieldName} />;
            }

            return (
              <Grid item md={6} key={fieldName}>
                <Box mb={3}>
                  <FormControl fullWidth error={isError}>
                    <InputLabel id={name}>{name}</InputLabel>
                    <Controller
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          {...field}
                          fullWidth
                          labelId={name}
                          value={field.value || ""}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {options.map((option) => (
                            <MenuItem value={option} key={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      name={fieldName}
                      control={control}
                    />
                  </FormControl>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box marginY={1}>
        <Toolbar color="secondary" size="small">
          NFT levels
        </Toolbar>
      </Box>

      <Box p={3}>
        <Grid container spacing={2}>
          {NFT_LEVELS.map(({ name, max_value: max }, index) => {
            const fieldName = generateFieldName("levels", index, name);
            const isError = hasErrorField(errors, "levels", index, name);
            const isMaxError =
              isError && errors.levels[index][name].type === "max";

            return (
              <Grid item md={6} key={fieldName}>
                <Box mb={3}>
                  <Controller
                    name={fieldName}
                    control={control}
                    rules={{
                      required: true,
                      min: 0,
                      max,
                    }}
                    render={({ field: { value = "", onChange, ref } }) => (
                      <TextField
                        type="number"
                        label={name}
                        fullWidth
                        autoComplete="off"
                        error={isError}
                        helperText={isMaxError && `Max value is ${max}`}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item md={6} container alignContent="center">
            <ButtonWithLoading
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={isDisabled}
              loading={loading}
            >
              Upload NFT METADATA to IPFS
            </ButtonWithLoading>
          </Grid>

          <Grid item md={6}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

MetadataForm.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  hash: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default MetadataForm;
