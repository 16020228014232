import MuiTextField from "@material-ui/core/TextField";

const TextField = (props) => (
  <MuiTextField
    InputLabelProps={{
      shrink: true,
    }}
    {...props}
  />
);

export default TextField;
