import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { TextField, Toolbar, ButtonWithLoading } from "components";

const PublishNFTForm = ({ isDisabled, onSubmit, loading, onReset, hash }) => (
  <>
    <Toolbar>Step 3 - publish NFT token to OPENSEA.IO</Toolbar>

    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item md={6} container alignContent="center">
          <ButtonWithLoading
            fullWidth
            color="primary"
            variant="contained"
            onClick={onSubmit}
            disabled={isDisabled}
            loading={loading}
          >
            Publish NFT token to OPENSEA.IO
          </ButtonWithLoading>
        </Grid>

        <Grid item md={6}>
          <TextField
            label="NFT Token address"
            fullWidth
            disabled
            value={hash}
          />
        </Grid>

        <Grid item md={6} />

        <Grid item md={6}>
          <Button
            fullWidth
            variant="contained"
            disabled={isDisabled}
            onClick={onReset}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </Box>
  </>
);

PublishNFTForm.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hash: PropTypes.string.isRequired,
};

export default PublishNFTForm;
