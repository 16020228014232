const readFile = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(file);
  });

export const convertFileToBuffer = async (file) => {
  const readerResult = await readFile(file);
  const buffer = await Buffer.from(readerResult);
  return buffer;
};
