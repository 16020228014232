import { useState, useContext } from "react";
import { useSnackbar } from "notistack";

import { convertFileToBuffer } from "helpers/file";
import {
  prepareMetadataArray,
  prepareMetadataNumbersArray,
} from "helpers/metadata";
import ipfsClient from "utils/ipfs";
import { mintToken } from "utils/ethers";
import { NFT_LEVELS } from "config";
import { WalletContext } from "context";

import { TextField } from "components";
import { UploadFileForm, MetadataForm, PublishNFTForm } from "./components";

const INFURA_URL = "https://ipfs.infura.io/ipfs/";

const Form = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { account } = useContext(WalletContext);
  const [loading, setLoading] = useState(false);
  const [ipfsImageHash, setIpfsImageHash] = useState("");
  const [ipfsMetadataHash, setIpfsMetadataHash] = useState("");
  const [NFTTokenAddress, setNFTTokenAddress] = useState("");

  const showErrorMessage = () => {
    enqueueSnackbar("Something went wrong. Please, try again", {
      variant: "error",
    });
  };

  const showSuccessMessage = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
    });
  };

  const handleUploadFileFormSubmit = async (data) => {
    try {
      setLoading(true);

      const file = data.image[0];
      const buffer = await convertFileToBuffer(file);
      const { path } = await ipfsClient.add(buffer);
      setIpfsImageHash(path);
      showSuccessMessage("IPFS Image hash successfully created");
    } catch (error) {
      console.log(error);
      showErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  const handleMetadataFormSubmit = async (data) => {
    try {
      setLoading(true);

      const accessories = prepareMetadataArray(data.accessories);
      const levels = prepareMetadataNumbersArray(data.levels, NFT_LEVELS);
      const metadata = {
        name: data.name,
        external_url: data.external_url,
        description: data.description,
        image: `${INFURA_URL}${ipfsImageHash}`,
        attributes: [...accessories, ...levels],
      };

      const buffer = Buffer.from(JSON.stringify(metadata));
      const { path } = await ipfsClient.add(buffer);
      setIpfsMetadataHash(path);
      showSuccessMessage("IPFS Metadata hash successfully created");
    } catch (error) {
      console.log(error);
      showErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  const handlePublishNFTToken = async () => {
    if (account) {
      try {
        setLoading(true);

        const NFTToken = await mintToken(account, ipfsMetadataHash);
        setNFTTokenAddress(NFTToken);
        showSuccessMessage("Successfully published");
      } catch (error) {
        console.log(error);
        showErrorMessage();
      } finally {
        setLoading(false);
      }
    } else {
      enqueueSnackbar("Please, install and connect Metamask", {
        variant: "error",
      });
    }
  };

  const handleReset = () => {
    setIpfsImageHash("");
    setIpfsMetadataHash("");
    setNFTTokenAddress("");
  };

  const handleIpfsImageHashChange = ({ target: { value } }) => {
    setIpfsImageHash(value);
  };

  const handleIpfsMetadataHashChange = ({ target: { value } }) => {
    setIpfsMetadataHash(value);
  };

  return (
    <>
      <UploadFileForm
        onSubmit={handleUploadFileFormSubmit}
        hash={ipfsImageHash}
        loading={loading}
      >
        <TextField
          label="NFT image IPFS hash"
          fullWidth
          value={ipfsImageHash}
          onChange={handleIpfsImageHashChange}
        />
      </UploadFileForm>

      <MetadataForm
        onSubmit={handleMetadataFormSubmit}
        hash={ipfsMetadataHash}
        isDisabled={!ipfsImageHash}
        loading={loading}
      >
        <TextField
          label="NFT Metadata IPFS hash"
          fullWidth
          value={ipfsMetadataHash}
          onChange={handleIpfsMetadataHashChange}
        />
      </MetadataForm>

      <PublishNFTForm
        isDisabled={!ipfsMetadataHash}
        onSubmit={handlePublishNFTToken}
        loading={loading}
        onReset={handleReset}
        hash={NFTTokenAddress}
      />
    </>
  );
};

export default Form;
