export const NFT_PROPERTIES = [{
        name: "Collateral",
        options: [
            "ERC20 assets"
        ],
    },
    // {
    //   name: "Location",
    //   options: [
    //     "Blue Forest",
    //     "Red Sky",
    //     "Library",
    //     "Place Of Power",
    //     "Hall",
    //     "Unknown",
    //     "Green Forest",
    //     "Tech Room",
    //     "Cave",
    //     "Gothic",
    //   ],
    // },
    // {
    //   name: "Coat",
    //   options: ["Black", "Red", "Brown", "White", "Blue", "Purple", "Green"],
    // },
    // {
    //   name: "Medallion",
    //   options: [
    //     "Greece",
    //     "Vesuvius",
    //     "Japanese",
    //     "God of War",
    //     "Maya",
    //     "Sacred",
    //     "Egypt",
    //     "Scythia",
    //     "Olymp",
    //     "Tech",
    //   ],
    // },
    // {
    //   name: "Teeth",
    //   options: ["Wild", "Rasor", "Tusk"],
    // },
    // {
    //   name: "Stick",
    //   options: [
    //     "Nature Spirit",
    //     "Ram",
    //     "Sickle",
    //     "Shaman spear",
    //     "Baculus",
    //     "Eagle",
    //     "Spear",
    //     "Lattern",
    //     "Sword",
    //     "Sa Spear",
    //     "Sa Lattern",
    //     "Sa Baculus",
    //     "C Lattern",
    //     "C Spear",
    //     "C Warder",
    //     "V Lattern",
    //     "V Spear",
    //     "V Jingu Bang",
    //     "AML Lattern",
    //     "AML Spear",
    //     "AML Techno",
    //     "Sk Lattern",
    //     "Sk Spear",
    //     "Sk Bone ",
    //     "Sh Snake",
    //     "Sh Spear",
    //     "Sh Lattern",
    //   ],
    // },
    // {
    //   name: "Beard",
    //   options: [
    //     "Brown Short",
    //     "Brown Long",
    //     "Brown S Long",
    //     "Blonde Short",
    //     "Blonde Long",
    //     "Blonde S Long",
    //     "Grey Short",
    //     "Grey Long",
    //     "Grey S Long",
    //     "Black Short",
    //     "Black Long",
    //     "Black S Long",
    //     "Colour Short",
    //     "Colour Long",
    //     "Colour S Long",
    //   ],
    // },
    // {
    //   name: "Mustache",
    //   options: [
    //     "Brown French",
    //     "Black French",
    //     "Grey French",
    //     "Brown St ",
    //     "Black St",
    //     "Grey St",
    //     "Colour St",
    //     "Asian",
    //   ],
    // },
    // {
    //   name: "Book",
    //   options: [
    //     "Orlic",
    //     "Lovecraft",
    //     "Asimov",
    //     "Darvin",
    //     "Howking",
    //     "Manuscipts 33",
    //     "Space 2030",
    //     "Stone",
    //     "Rowling",
    //     "Da Vinci",
    //   ],
    // },
    // {
    //   name: "Skin",
    //   options: [
    //     "Dark",
    //     "Deadly White",
    //     "None",
    //     "Blue",
    //     "Green",
    //     "Red",
    //     "Unknown",
    //     "Steel",
    //     "Fur",
    //   ],
    // },
    // {
    //   name: "Ring",
    //   options: [
    //     "Celestial",
    //     "Silver Tulip",
    //     "Lightning",
    //     "Kraken’s eye",
    //     "Spirit Ring",
    //     "Golden Hydra",
    //     "Claw",
    //     "Shadow Ring",
    //     "Solomon Ring",
    //     "Techno Ring",
    //     "Order Ring",
    //   ],
    // },
    // {
    //   name: "Hair",
    //   options: ["Brown", "Black", "Blonde", "Grey", "Colour"],
    // },
    // {
    //   name: "Mask",
    //   options: ["Samurai", "Golden", "Respirator", "AML Special", "Unique"],
    // },
    // {
    //   name: "Tattoo",
    //   options: ["Tech", "Ethnic", "Architect", "Blackwork", "Pattern"],
    // },
    // {
    //   name: "Animal",
    //   options: [
    //     "L Cat",
    //     "L Snake",
    //     "L Owl",
    //     "L Salamander",
    //     "L Rat",
    //     "L Eagle",
    //     "L Sapsan",
    //     "L Griffin",
    //     "L Hummingbird",
    //     "L Parrot",
    //     "L Fish",
    //     "L Horse",
    //     "L Griffon",
    //     "L Ferret",
    //     "L Skelius",
    //     "L Monkey",
    //     "L Deer",
    //     "L Apep",
    //     "L Spider",
    //     "L Bull",
    //     "L Velociraptor",
    //     "L Iguana",
    //     "L Pterosaur",
    //     "L Saltopus",
    //     "L Unicorn",
    //     "L Fox",
    //     "L Ironclad",
    //     "L Beatle",
    //     "L Dragon",
    //     "L Creature X",
    //     "L MutantBird",
    //     "L MutantCat",
    //     "L MutantLizard",
    //     "L MutantSnake",
    //     "L Frog",
    //     "L Raven",
    //     "L TechnoBird",
    //     "L TechnoBull",
    //     "L TechnoDragon",
    //     "L TechnoSpider",
    //     "L TechnoSnake",
    //     "L AlienBird",
    //     "L Spirit",
    //     "L Unknown ",
    //     "Bailong",
    //     "D Cat",
    //     "D Snake",
    //     "D Owl",
    //     "D Salamander",
    //     "D Rat",
    //     "D Eagle",
    //     "D Sapsan",
    //     "D Griffin",
    //     "D Hummingbird",
    //     "D Parrot",
    //     "D Fish",
    //     "D Horse",
    //     "D Griffon",
    //     "D Ferret",
    //     "D Skelius",
    //     "D Monkey",
    //     "D Deer",
    //     "D Apep",
    //     "D Spider",
    //     "D Bull",
    //     "D Velociraptor",
    //     "D Iguana",
    //     "D Pterosaur",
    //     "D Saltopus",
    //     "D Unicorn",
    //     "D Fox",
    //     "D Ironclad",
    //     "D Beetle",
    //     "D Dragon",
    //     "D Creature X",
    //     "D MutantBird",
    //     "D MutantCat",
    //     "D MutantLizard",
    //     "D MutantSnake",
    //     "D Frog",
    //     "D Raven",
    //     "D TechnoBird",
    //     "D TechnoBull",
    //     "D TechnoDragon",
    //     "D TechnoSpider",
    //     "D TechnoSnake",
    //     "D AlienBird",
    //     "D Spirit",
    //     "D Unknown",
    //     "Heilong",
    //   ],
    // },
    // {},
    // {
    //   name: "Accessories",
    //   options: [
    //     "Golden Pect",
    //     "Silver Pect",
    //     "Colour Pect",
    //     "Colour Pipe",
    //     "Golden Pipe",
    //     "Silver Pipe",
    //     "Beard",
    //     "Cable",
    //     "Golden Ros",
    //     "Silver Ros",
    //     "Gradient Ros",
    //     "Black Gloves",
    //     "Brown Gloves",
    //     "Golden Pattern",
    //     "Silver Pattern ",
    //     "Black Cape",
    //     "Red Cape",
    //     "White Cape",
    //     "Blue Cape",
    //     "Green Cape ",
    //     "Purple Cape",
    //     "Brown Cape",
    //     "Fur",
    //     "Golden Braslet",
    //     "Silver Braslet",
    //     "Single Chain",
    //     "Double Chain",
    //     "Triple Chain",
    //     "100",
    //   ],
    // },
    // {
    //   name: "Accessories",
    //   options: [
    //     "Golden Pect",
    //     "Silver Pect",
    //     "Colour Pect",
    //     "Colour Pipe",
    //     "Golden Pipe",
    //     "Silver Pipe",
    //     "Beard",
    //     "Cable",
    //     "Golden Ros",
    //     "Silver Ros",
    //     "Gradient Ros",
    //     "Black Gloves",
    //     "Brown Gloves",
    //     "Golden Pattern",
    //     "Silver Pattern ",
    //     "Black Cape",
    //     "Red Cape",
    //     "White Cape",
    //     "Blue Cape",
    //     "Green Cape ",
    //     "Purple Cape",
    //     "Brown Cape",
    //     "Fur",
    //     "Golden Braslet",
    //     "Silver Braslet",
    //     "Single Chain",
    //     "Double Chain",
    //     "Triple Chain",
    //     "100",
    //   ],
    // },
    // {
    //   name: "Accessories",
    //   options: [
    //     "Golden Pect",
    //     "Silver Pect",
    //     "Colour Pect",
    //     "Colour Pipe",
    //     "Golden Pipe",
    //     "Silver Pipe",
    //     "Beard",
    //     "Cable",
    //     "Golden Ros",
    //     "Silver Ros",
    //     "Gradient Ros",
    //     "Black Gloves",
    //     "Brown Gloves",
    //     "Golden Pattern",
    //     "Silver Pattern ",
    //     "Black Cape",
    //     "Red Cape",
    //     "White Cape",
    //     "Blue Cape",
    //     "Green Cape ",
    //     "Purple Cape",
    //     "Brown Cape",
    //     "Fur",
    //     "Golden Braslet",
    //     "Silver Braslet",
    //     "Single Chain",
    //     "Double Chain",
    //     "Triple Chain",
    //     "100",
    //   ],
    // },
    // {
    //   name: "Accessories",
    //   options: [
    //     "Golden Pect",
    //     "Silver Pect",
    //     "Colour Pect",
    //     "Colour Pipe",
    //     "Golden Pipe",
    //     "Silver Pipe",
    //     "Beard",
    //     "Cable",
    //     "Golden Ros",
    //     "Silver Ros",
    //     "Gradient Ros",
    //     "Black Gloves",
    //     "Brown Gloves",
    //     "Golden Pattern",
    //     "Silver Pattern ",
    //     "Black Cape",
    //     "Red Cape",
    //     "White Cape",
    //     "Blue Cape",
    //     "Green Cape ",
    //     "Purple Cape",
    //     "Brown Cape",
    //     "Fur",
    //     "Golden Braslet",
    //     "Silver Braslet",
    //     "Single Chain",
    //     "Double Chain",
    //     "Triple Chain",
    //     "100",
    //   ],
    // },
];

export const NFT_LEVELS = [{
    name: "Points",
    max_value: 1000,
}, ];