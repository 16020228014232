import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import MuiToolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#fff",
  },
  toolbar: {
    justifyContent: "center",
  },
  primary: {
    backgroundColor: theme.palette.primary.dark,
  },
  secondary: {
    backgroundColor: theme.palette.primary.light,
  },
  default: {},
  small: {
    minHeight: 48,
  },
}));

const Toolbar = ({ children, color, size }) => {
  const classes = useStyles();
  const variant = size === "small" ? "body1" : "h6";

  return (
    <MuiToolbar
      className={clsx(classes.toolbar, classes[color], classes[size])}
    >
      <Typography variant={variant} component="h2" className={classes.title}>
        {children}
      </Typography>
    </MuiToolbar>
  );
};

Toolbar.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["primary", "secondary"]),
  size: PropTypes.oneOf(["default", "small"]),
};

Toolbar.defaultProps = {
  color: "primary",
  size: "default",
};

export default Toolbar;
