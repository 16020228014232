import { ethers } from "ethers";

import { getBytes32FromIpfsHash } from "helpers/base58";
import { createExplorerLink } from "helpers/link";

const { ethereum } = window;
let provider;
let network;

const SC_ADDRESS = process.env.REACT_APP_SC_ERC721;
const abi = [
  "function mintTo(address _to, bytes32 _hash) public",
  "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
];

const setProvider = () => {
  provider = new ethers.providers.Web3Provider(ethereum);
};

const setNetwork = async () => {
  network = await provider.getNetwork();
};

export const isMetamaskExist = () => Boolean(ethereum);

const initWalletListeners = (accountsChangedHandler) => {
  ethereum.on("chainChanged", () => window.location.reload());
  ethereum.on("accountsChanged", accountsChangedHandler);
};

export const initWallet = async (accountsChangedHandler) => {
  setProvider();
  await setNetwork();
  initWalletListeners(accountsChangedHandler);
};

export const getAccount = async () => {
  const accounts = await ethereum.request({
    method: "eth_requestAccounts",
  });
  return accounts[0];
};

export const getBalance = async (address) => {
  const balance = await provider.getBalance(address);
  return ethers.utils.formatEther(balance);
};

export const mintToken = async (account, ipfsHash) => {
  const signer = provider.getSigner();
  const contract = new ethers.Contract(SC_ADDRESS, abi, signer);
  const hash = getBytes32FromIpfsHash(ipfsHash);

  const mintedToken = await contract.mintTo(account, hash);
  const { events, to } = await mintedToken.wait();
  const {
    args: { tokenId },
  } = events[0];

  return createExplorerLink(network.chainId, to, tokenId.toString());
};
