import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { useSnackbar } from "notistack";

import {
  getBalance,
  getAccount,
  isMetamaskExist,
  initWallet,
} from "utils/ethers";

import WalletContext from "./WalletContext";

const WalletProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [account, setAccount] = useState("");
  const [balance, setBalance] = useState(0);

  const handleAccountChange = async (accounts) => {
    const newAccount = accounts[0];
    const walletBalance = await getBalance(newAccount);

    setAccount(newAccount);
    setBalance(walletBalance);
  };

  const connect = useCallback(async () => {
    if (isMetamaskExist()) {
      await initWallet(handleAccountChange);
      const walletAccount = await getAccount();
      const walletBalance = await getBalance(walletAccount);

      setAccount(walletAccount);
      setBalance(walletBalance);
    } else {
      enqueueSnackbar("Please, install and connect Metamask", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    connect();
  }, [connect]);

  return (
    <WalletContext.Provider
      value={{
        account,
        balance,
        connect,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

WalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WalletProvider;
