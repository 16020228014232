const TYPE_KEY = "trait_type";

export const prepareMetadataArray = (data) =>
  data
    .reduce((array, accessory) => {
      const key = Object.keys(accessory)[0];

      return [
        ...array,
        {
          [TYPE_KEY]: key,
          value: accessory[key],
        },
      ];
    }, [])
    .filter((property) => property.value);

export const prepareMetadataNumbersArray = (data, maxValues) =>
  data.reduce((array, accessory, index) => {
    const key = Object.keys(accessory)[0];
    return [
      ...array,
      {
        [TYPE_KEY]: key,
        value: Number(accessory[key]),
        max_value: maxValues[index].max_value,
      },
    ];
  }, []);
