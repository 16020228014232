import { useContext } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import { getShortWalletAddress } from "helpers/walletAddress";
import { WalletContext } from "context";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,

    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",
  },
  balance: {
    flexGrow: 1,

    fontWeight: theme.typography.fontWeightBold,
  },
}));

const Header = () => {
  const classes = useStyles();
  const { connect, account, balance } = useContext(WalletContext);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h4" component="h1" className={classes.title}>
            NFT Token Uploader
          </Typography>

          {account ? (
            <>
              <Typography className={classes.balance}>
                ETH: {balance}
              </Typography>
              <Typography>{getShortWalletAddress(account)}</Typography>
            </>
          ) : (
            <Button variant="contained" onClick={connect}>
              Connect Wallet
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
