const URL = "opensea.io/assets";

const getPrefixForNetwork = (network) => {
  const net = Number(network);
  let prefix;

  switch (net) {
    case 1: // main net
      prefix = "";
      break;
    default:
      prefix = "testnets.";
  }

  return prefix;
};

export const createExplorerLink = (network, address, tokenID) => {
  const prefix = getPrefixForNetwork(network);
  return `https://${prefix}${URL}/${address}/${tokenID}`;
};
